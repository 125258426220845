import _ from 'underscore';

import { MenuRules } from '@biteinc/business-rules';
import { BiteUrl } from '@biteinc/common';
import {
  allEnumValues,
  ComboUpsellType,
  ComboUpsellTypeHelper,
  IntegrationSystem,
  ItemHiddenState,
  ItemTaxationStatus,
  ItemTaxationStatusHelper,
  MenuItemCategory,
  MenuItemDisplayBlockCarouselItemType,
  MenuItemDisplayBlockListItemType,
  MenuItemDisplayBlockType,
  MenuItemDisplayBlockTypeHelper,
  MenuItemDisplayStyle,
  MenuItemSaleUnit,
  ModelType,
} from '@biteinc/enums';
import { MathHelper, StringHelper } from '@biteinc/helpers';
import { menuItemSchema } from '@biteinc/schemas';

import { TimeHelper } from '../helpers/time_helper';

app.MenuItem = app.AbstractModel.extend({
  ModelName: 'item',
  Schema: menuItemSchema,
  Type: ModelType.MenuItem,

  defaults() {
    const defaults = {
      displayStyle: MenuItemDisplayStyle.NameAndDescription,
      category: MenuItemCategory.Food,
      isMod: false,
      taxationStatus: ItemTaxationStatus.Taxable,
      priceOptions: [{ _id: StringHelper.randomHexString(24) }],
    };
    if (app.taxProfileList?.hasBeenFetched() && app.taxProfileList.size()) {
      defaults.taxProfileId = app.taxProfileList.at(0).id;
    }
    if (app.vendorList?.hasBeenFetched() && app.vendorList.size()) {
      defaults.vendorId = (
        app.vendorList.isEveryVendorIntegrated()
          ? app.vendorList.getDefaultVendorOrFallback()
          : app.vendorList.getDefaultNonintegratedVendor()
      ).id;
    }
    return defaults;
  },

  isMod() {
    return !!this.get('isMod');
  },

  canChangeParent() {
    return !this.getFullVendor().syncsModelsWithType(ModelType.MenuSection);
  },

  canBeDestroyed() {
    if (!app.location) {
      return false;
    }
    if (!this.isMod() && this.get('variationSource')) {
      return true;
    }
    return app.AbstractModel.prototype.canBeDestroyed.apply(this);
  },

  hasModWithId(modId) {
    return this.get('priceOptions').some((priceOption) => {
      return !!priceOption.addonSetIds?.some((modGroupId) => {
        const modGroup = app.modGroupList.get(modGroupId);
        return !!modGroup?.hasVisibleModWithId(modId);
      });
    });
  },

  hasModGroupWithId(modGroupId) {
    return _.any(this.get('priceOptions'), (priceOption) => {
      return (
        _.contains(priceOption.addonSetIds, modGroupId) ||
        _.contains(priceOption.floatingModGroupIds || [], modGroupId)
      );
    });
  },

  displayNameHtml() {
    const hiddenState = this._getHiddenState();
    let title = `<span>${this.displayName()}`;
    if (hiddenState) {
      title += ` (${hiddenState})`;
    }
    title += '</span>';
    if (this.isMissingImages()) {
      title +=
        '&nbsp;&nbsp;<img class="badge-img" src="https://static.bureau.getbite.com/images/no-photo.png" ' +
        'srcset="https://static.bureau.getbite.com/images/no-photo@2x.png 2x" title="No Photos"/>';
    }
    if (app.badgeList) {
      _.each(this.get('badgeIds') || [], (badgeId) => {
        const badge = app.badgeList.get(badgeId);
        if (badge) {
          const url = BiteUrl.biteAssetUrlWithNewHost(badge.get('icons')[0].url);
          const name = badge.get('name');
          title += `&nbsp;&nbsp;<img class="badge-img" src="${url}" title="${name}" />`;
        }
      });
    }

    const hasBarcode = _.any(this.get('priceOptions'), (priceOption) => {
      return (priceOption.barcode || priceOption.posBarcode || '').length > 0;
    });
    if (hasBarcode) {
      title +=
        '&nbsp;&nbsp;<img class="badge-img" src="https://static.bureau.getbite.com/images/icon-barcode@2x.png" ' +
        'srcset="https://static.bureau.getbite.com/images/icon-barcode@2x.png 2x" title="Has Barcode"/>';
    }

    if (this.get('archivedAt')) {
      let alt = `Archived on ${TimeHelper.format(this.get('archivedAt'))}. Won't show in menu.`;
      if (this.isMod()) {
        alt += ' Will be removed a week after that.';
      } else {
        alt += ' Will be kept indefinitely.';
      }
      title += ` <span class="cell-badge archived" title="${alt}">archived</span>`;
    }

    if (this.hasArr('constituentItems')) {
      title += ' <span class="cell-badge compound-item" title="Compound Menu Item">compound</span>';
    }
    if (this.get('invalidReason')) {
      title += ' <span class="cell-badge error" title="Invalid Menu Item">invalid</span>';
    }

    return title;
  },

  priceOptionCanBeHidden(priceOptionId) {
    const po = this.priceOptionById(priceOptionId);
    return po && !po.i9nHiddenState;
  },

  _getHiddenState() {
    const hiddenOnPos = _.all(this.get('priceOptions'), (po) => {
      return po.i9nHiddenState === ItemHiddenState.HiddenIndefinitely;
    });
    if (hiddenOnPos) {
      return 'hidden on POS';
    }
    const hiddenIndefinitely = _.all(this.get('priceOptions'), (po) => {
      return po.hiddenState === ItemHiddenState.HiddenIndefinitely;
    });
    if (hiddenIndefinitely) {
      return 'hidden indefinitely';
    }

    const hiddenUntilTomorrow = _.all(this.get('priceOptions'), (po) => {
      return po.hiddenState === ItemHiddenState.HiddenUntilTomorrow;
    });
    if (hiddenUntilTomorrow) {
      return 'hidden until tomorrow';
    }

    return null;
  },

  matchesQuery(query /* optDisplayName */) {
    if (!query) {
      return true;
    }

    const matchesBarcode = !!this.get('priceOptions').find((priceOption) => {
      return priceOption.barcode?.includes(query) || priceOption.posBarcode?.includes(query);
    });
    if (matchesBarcode) {
      return true;
    }

    switch (query) {
      case ':alcohol':
        return this.has('spiritId') || this.get('category') === MenuItemCategory.AlcoholicBeverage;
      case ':hidden':
        return !!this._getHiddenState();
      case ':no-images':
        return !this.hasArr('images');
      case ':orphan':
        return !this.getVisibleInPlaces().length;
      case ':is-customized':
        return this.hasCustomizations();
    }
    return app.AbstractModel.prototype.matchesQuery.apply(this, arguments);
  },

  isMissingImages() {
    return !this.hasArr('images');
  },

  // Only works for mods right now
  hasCustomizations() {
    return (
      this.hasStr('name') ||
      this.hasStr('description') ||
      this.hasArr('images') ||
      this.hasArr('badgeIds')
    );
  },

  removeModGroup(modGroup) {
    const priceOptions = [];
    _.each(this._priceOptions(), (priceOption) => {
      priceOptions.push(_.clone(priceOption));
    });
    _.each(priceOptions, (priceOption) => {
      const modGroupIdIndex = this._modGroupIdIndex(priceOption, modGroup.id);
      if (modGroupIdIndex >= 0) {
        const modGroupIds = [].concat(priceOption.addonSetIds);
        modGroupIds.splice(modGroupIdIndex, 1);
        priceOption.addonSetIds = modGroupIds;
      }
    });
    this.set('priceOptions', priceOptions);
  },

  removeBadge(badge) {
    app.JsonHelper.removeElementFromArray(this.get('badgeIds'), badge.id);
  },

  _priceOptions() {
    return this.get('priceOptions') || [];
  },

  _addCaloriesToName(name) {
    /**
     * Show Nutrition Info and do a check for the old hack just in case
     * @todo Remove hack for name check
     */
    if (!app.location) {
      return name;
    }
    const showNutritionInfo = app.locationSettings.get('showNutritionInfo');
    const nutritionInfo = this.get('nutritionInfo');
    const menuItemHasNutritionInfo = _.isObject(nutritionInfo) && !_.isEmpty(nutritionInfo);
    const matches = name.match(/(([0-9]+ \| )*[0-9]+ cal)$/i);
    if (
      showNutritionInfo &&
      menuItemHasNutritionInfo &&
      !_.size(matches) &&
      (nutritionInfo.baseCalories || nutritionInfo.posBaseCalories)
    ) {
      const baseCalories = nutritionInfo.baseCalories || nutritionInfo.posBaseCalories;
      const maxCalories = nutritionInfo.maxCalories || nutritionInfo.posMaxCalories;
      const caloriesStr = app.HtmlHelper.stringFromCalorieRange(baseCalories, maxCalories);
      return `${name} [${caloriesStr} ${app.location.getCalorieSuffix()}]`;
    }
    return name;
  },

  displayName() {
    let name = app.AbstractModel.prototype.displayName.apply(this, arguments);
    if (!name) {
      return name;
    }

    name = this._addCaloriesToName(name);
    if (this._priceOptions().length === 1) {
      const priceOption = this._priceOptions()[0];
      const price = priceOption.price;
      if (price > 0 || this.isMod()) {
        name += ` ($${MathHelper.displayPrice(price, priceOption.saleUnit)})`;
      } else if (!_.size(priceOption.addonSetIds)) {
        name += ' (FREE)';
      }
    }
    return name;
  },

  displayPrice(priceOptionId) {
    const priceOption = this.priceOptionById(priceOptionId);
    return MathHelper.displayPrice(priceOption.price, priceOption.saleUnit);
  },

  recoId() {
    return StringHelper.slug(this.get('posName') || this.get('name') || '');
  },

  priceOptionById(priceOptionId, priceOptions) {
    // eslint-disable-next-line no-param-reassign
    priceOptions = priceOptions || this._priceOptions();
    for (let i = 0; i < priceOptions.length; i++) {
      if (!priceOptionId || priceOptions[i]._id === priceOptionId) {
        return priceOptions[i];
      }
    }
    return null;
  },

  _modGroupIdIndex(priceOption, modGroupId) {
    for (let i = 0; i < _.size(priceOption.addonSetIds); i++) {
      if (priceOption.addonSetIds[i] === modGroupId) {
        return i;
      }
    }
    return -1;
  },

  getIntraLocationCopyPayload(fieldNames, toModelIds) {
    return {
      fromMenuItemId: this.id,
      toMenuItemIds: toModelIds,
      fieldNames,
    };
  },

  updateHiddenStateForPriceOption(hiddenState, priceOptionId, cb) {
    const self = this;
    app.makeRequest(
      'PUT',
      `${this.url()}/hidden-state`,
      {
        hiddenState,
        priceOptionId,
      },
      () => {
        const priceOption = self.priceOptionById(priceOptionId);
        if (priceOption) {
          priceOption.hiddenState = hiddenState;
        }
        cb();
      },
      (error) => {
        cb(error);
      },
    );
  },

  getVisibleInPlaces() {
    const collection = this.isMod() ? app.modGroupList : app.menuSectionList;
    const models = collection.filter((itemArray) => {
      return itemArray.hasItemWithId(this.id);
    });
    if (!this.isMod()) {
      app.menuItemList.each((item) => {
        if (item.hasConstituentItemWithId(this.id)) {
          models.push(item);
        }
      });
    }
    return models;
  },

  fieldNamesThatCanBeCopiedToOtherModels() {
    return MenuRules.menuItemFieldNamesThatCanBeCopiedToOtherModels();
  },

  getFieldCollection(field, subProperty, includeAllValues, _keyModel) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    switch (fieldName) {
      case 'vendorId':
        return this.getFullVendor().syncsModelsWithType(this.Type)
          ? app.vendorList.buildSingletonVendorList(this.get('vendorId'))
          : app.vendorList.buildListOfVendorsThatDoNotSyncModelsWithType(this.Type);
      case 'badgeIds':
        return app.badgeList;
      case 'category':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemCategory,
          nameGenerator: (category) => {
            switch (category) {
              case MenuItemCategory.Food:
                return 'Food';
              case MenuItemCategory.AlcoholicBeverage:
                return 'Alcoholic Beverage';
              case MenuItemCategory.NonalcoholicBeverage:
                return 'Nonalcoholic Beverage';
              case MenuItemCategory.Other:
                return 'Other';
              case MenuItemCategory.SpecialOffer:
                return 'Special Offer';
              default:
                throw new Error(`unrecognized category value: ${category}`);
            }
          },
        });
      case 'displayStyle': {
        const Model = app.AbstractModel.extend({
          displayNameHtml() {
            const displayName = this.displayName();
            switch (this.id) {
              case MenuItemDisplayStyle.NameAndDescription:
                return `<img class="dropdown-img" src="https://static.bureau.getbite.com/images/display-style-plain.png"/>${displayName}`;
              case MenuItemDisplayStyle.NameAndImage:
                return `<img class="dropdown-img" src="https://static.bureau.getbite.com/images/display-style-plain-pic.png"/>${displayName}`;
              case MenuItemDisplayStyle.Wide:
                return `<img class="dropdown-img" src="https://static.bureau.getbite.com/images/display-style-horizontal.png"/>${displayName}`;
              case MenuItemDisplayStyle.NameDescriptionAndImage:
                return `<img class="dropdown-img" src="https://static.bureau.getbite.com/images/display-style-plain-pic-description.png"/>${displayName}`;
              default:
                throw new Error(`unrecognized displayStyle value: ${this.id}`);
            }
          },
        });
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemDisplayStyle,
          ModelClass: Model,
          nameGenerator: (displayStyle) => {
            switch (displayStyle) {
              case MenuItemDisplayStyle.NameAndDescription:
                return 'Name/Description';
              case MenuItemDisplayStyle.NameAndImage:
                return 'Name/Image';
              case MenuItemDisplayStyle.Wide:
                return 'Horizontal (with Image)';
              case MenuItemDisplayStyle.NameDescriptionAndImage:
                return 'Name/Description/Image';
              default:
                throw new Error(`unrecognized displayStyle value: ${displayStyle}`);
            }
          },
        });
      }
      case 'comboUpsell.type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ComboUpsellType,
          nameGenerator: ComboUpsellTypeHelper.name,
        });
      case 'comboUpsell.comboItemId': {
        return app.menuItemList;
      }
      case 'upsellModifierItemId': {
        const mods = [];
        _.each(this.get('priceOptions'), (po) => {
          po.addonSetIds?.forEach((id) => {
            const modGroup = app.modGroupList.get(id);
            if (modGroup) {
              _.each(modGroup.get('items'), (item) => {
                const mod = app.modList.get(item._id);
                if (mod) {
                  mods.push(mod);
                }
              });
            }
          });
        });

        const ModList = app.AbstractCollection.extend({
          model: app.Mod,
        });
        return new ModList(mods);
      }
      case 'priceOptions.addonSetIds':
        return this.getFullVendor().syncsModelsWithType(this.Type)
          ? app.modGroupList.buildModGroupsWithVendorList(this.get('vendorId'))
          : app.modGroupList.buildNonintegratedModGroupsList();
      case 'priceOptions.floatingModGroupIds':
        return this.getFullVendor().syncsModelsWithType(this.Type)
          ? app.modGroupList.buildModGroupsWithVendorList(this.get('vendorId'))
          : app.modGroupList.buildNonintegratedModGroupsList();
      case 'priceOptions.saleUnit':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemSaleUnit,
          nameGenerator: (saleUnit) => {
            switch (saleUnit) {
              case MenuItemSaleUnit.Each:
                return 'Each';
              case MenuItemSaleUnit.LB:
                return 'Pounds';
              case MenuItemSaleUnit.OZ:
                return 'Ounces';
              default:
                throw new Error(`unrecognized saleUnit value: ${saleUnit}`);
            }
          },
        });
      case 'constituentItems': {
        const selectableConstituentItems = app.menuItemList.clone();
        const compoundItems = _.filter(selectableConstituentItems.models, (item) => {
          return _.size(item.get('constituentItems'));
        });
        selectableConstituentItems.remove(compoundItems);
        return selectableConstituentItems;
      }
      case 'taxationStatus':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ItemTaxationStatus,
          nameGenerator: (taxationStatus) => {
            switch (taxationStatus) {
              case ItemTaxationStatus.Taxable:
                return 'Taxable';
              case ItemTaxationStatus.TaxIncluded:
                return 'Tax Included';
              case ItemTaxationStatus.NotTaxable:
                return 'Not Taxable';
              case ItemTaxationStatus.Inherit:
                return 'Inherit from Menu Item';
              default:
                throw new Error(`unrecognized taxationStatus value: ${taxationStatus}`);
            }
          },
          sort: app.AbstractCollection.SortOptions.ID,
          // If we don't need to show everything, and this is not an mod, then we filter out
          // the INHERIT value since menu items can't inherit their tax status.
          values:
            includeAllValues || this.isMod()
              ? ItemTaxationStatusHelper.allValuesForMods()
              : ItemTaxationStatusHelper.allValuesForMenuItems(),
        });
      case 'taxProfileId':
        return app.taxProfileList;
      case 'activeTimings':
        return app.menuTimingList;
      case 'displayBlocks.type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemDisplayBlockType,
          values: allEnumValues(MenuItemDisplayBlockType).filter(
            MenuItemDisplayBlockTypeHelper.blockIsUserAccessible,
          ),
          nameGenerator: MenuItemDisplayBlockTypeHelper.name,
        });
      case 'displayBlocks.carouselItems.type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemDisplayBlockCarouselItemType,
          nameGenerator: (carouselItemType) => {
            return StringHelper.toTitleCase(carouselItemType);
          },
        });
      case 'displayBlocks.listItems.type':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: MenuItemDisplayBlockListItemType,
          nameGenerator: (listItemType) => {
            return StringHelper.toTitleCase(listItemType);
          },
        });
    }
    return null;
  },

  detailsViewClassForListField(field, subProperty) {
    const fieldName = subProperty ? [subProperty, field].join('.') : field;
    switch (fieldName) {
      case 'constituentItems':
        return app.MenuItemDetailsView;
      case 'priceOptions.addonSetIds':
      case 'priceOptions.floatingModGroupIds':
        return app.MenuItemArrayDetailsView;
    }
    return null;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    return plainTextOnly ? element.displayName() : element.displayNameHtml();
  },

  getModGroups() {
    if (!this._modGroups) {
      const modGroups = [];
      this.get('priceOptions').forEach((priceOption) => {
        priceOption.addonSetIds?.forEach((modGroupId) => {
          modGroups.push(app.modGroupList.get(modGroupId));
        });
      });
      this._modGroups = modGroups;
    }
    return this._modGroups;
  },

  canBePromotionItem() {
    // duplicate for the below validation can be found
    // at `MenuItem.canBePromotionItem`
    const modGroupsForItem = this.getModGroups();
    const isAnyMinSelectableNonZero = modGroupsForItem.some((mod) => {
      return mod.get('minSelectable') !== 0;
    });

    if (isAnyMinSelectableNonZero) {
      return false;
    }

    if (this.get('priceOptions').length !== 1) {
      return false;
    }

    if (this.get('priceOptions')[0].price !== 0) {
      return false;
    }
    return true;
  },

  _getModGroupForModId(modId) {
    return this.getModGroups().find((modGroup) => {
      return _.contains(_.pluck(modGroup.get('items'), '_id'), modId);
    });
  },

  getListFieldElementAttributesFromModel(field, element) {
    return { _id: element.id };
  },

  fieldIsPermanent(field /* subProperty */) {
    if (app.AbstractModel.prototype.fieldIsPermanent.apply(this, arguments)) {
      return true;
    }

    if (field === 'vendorId' && this.Schema.displayName === 'compound menu item') {
      return true;
    }

    const permanentI9nFields = ['vendorId', 'price', 'saleUnit', 'priceOptions', 'addonSetIds'];
    if (
      _.contains(permanentI9nFields, field) &&
      this.getFullVendor().syncsModelsWithType(this.Type)
    ) {
      return true;
    }

    if (
      _.contains(['taxProfileId', 'taxationStatus'], field) &&
      this.getFullVendor().syncsModelsWithType(this.Type) &&
      (this.getFullVendor().syncsModelsWithType(ModelType.TaxProfile) ||
        app.vendorList.isSomeVendorNonintegrated())
    ) {
      return true;
    }

    return false;
  },

  fieldIsHidden(field /* subProperty */) {
    if (app.AbstractModel.prototype.fieldIsHidden.apply(this, arguments)) {
      return true;
    }

    if (field === 'vendorId' && app.vendorList.isEveryVendorIntegrated()) {
      return true;
    }

    if (
      ['taxationStatus', 'taxProfileId'].includes(field) &&
      this.isSynced() &&
      !app.location.usesTaxProfiles()
    ) {
      return true;
    }

    return false;
  },

  isSynced() {
    return this.hasArr('integrations');
  },

  isCompoundItem() {
    return !!_.size(this.get('constituentItems'));
  },

  isVariationItem() {
    return !!this.get('variationSource');
  },

  canBePrecisionCopied() {
    return true;
  },

  hasConstituentItemWithId(itemId) {
    return _.any(this.get('constituentItems'), ({ _id: constituentItemId }) => {
      return constituentItemId === itemId;
    });
  },

  supportsDenormalizingModGroups() {
    if (!this.hasArr('integrations')) {
      return false;
    }

    const posSystem = this.get('integrations')[0].system;
    switch (posSystem) {
      case IntegrationSystem.Omnivore:
      case IntegrationSystem.Toast:
      case IntegrationSystem.ParBrink:
      case IntegrationSystem.Qu:
      case IntegrationSystem.Olo:
        return true;
      case IntegrationSystem.Simphony:
        return !this.isMod();
      default:
        return false;
    }
  },

  supportsUsingModCodes() {
    if (!this.hasArr('integrations')) {
      return false;
    }

    const posSystem = this.get('integrations')[0].system;
    if (this.isMod()) {
      switch (posSystem) {
        case IntegrationSystem.Revel:
        case IntegrationSystem.Simphony:
          return true;
        default:
          return false;
      }
    }

    // items can't support mod codes by definition
    return false;
  },

  getPosIdSummary() {
    if (this.get('posId') === this.get('i9nId')) {
      return this.get('posId');
    }

    const posSystem = this.get('integrations')[0].system;
    switch (posSystem) {
      case IntegrationSystem.Omnivore:
        return `posId: ${this.get('posId')}, omnivoreId: ${this.get('i9nId')}`;
      case IntegrationSystem.Olo:
        return `POS ID: ${this.get('posId')}, OlO ID: ${
          this.get('priceOptions')[0].i9nId || this.get('i9nId')
        }`;
      case IntegrationSystem.ParBrink:
        return `Item ID: ${this.get('posId')}, MenuItem ID: ${this.get('i9nId')}`;
      default:
        return this.get('posId');
    }
  },
});
